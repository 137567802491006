import { getApolloClient } from '@confluence/graphql';

import type {
	ConfluenceEdition,
	waitForEditionChangeQuery as waitForEditionChangeQueryType,
} from './__types__/waitForEditionChangeQuery';
import { waitForEditionChangeQuery } from './waitForEditionChangeQuery.graphql';
import { CHANGE_EDITION_POLL_DURATION_MINS, CHANGE_EDITION_POLL_INTERVAL_SECS } from './constants';

const timeNow = () => new Date().getTime();

export const TimeoutError = new Error('TIMEOUT');

/**
 * Get a promise that polls for changes to the edition and resolves with the new edition when it has changed from the current edition.
 *
 * @param {ConfluenceEdition|undefined} currentEdition - will keep polling until the edition no longer matches this or timeout.
 *                                                       If falsy, the promise will reject immediately.
 */
export const waitForEditionChange = (
	currentEdition: ConfluenceEdition | null | undefined,
): Promise<ConfluenceEdition> => {
	if (!currentEdition) {
		return Promise.reject(new Error('No currentEdition supplied'));
	}

	const pollEndTime = new Date(timeNow() + CHANGE_EDITION_POLL_DURATION_MINS * 60 * 1000).getTime();

	const pollAndResolve = (
		resolve: (value: ConfluenceEdition) => void,
		reject: (reason: Error) => void,
	) => {
		if (timeNow() > pollEndTime) {
			reject(TimeoutError);
		}
		getApolloClient()
			.query<waitForEditionChangeQueryType>({
				query: waitForEditionChangeQuery,
				errorPolicy: 'all',
				fetchPolicy: 'network-only',
			})
			.then((response) => {
				const polledEdition = response.data?.tenant?.editions?.edition;
				if (polledEdition && currentEdition !== polledEdition) {
					// Write the latest edition to cache.
					getApolloClient().writeQuery({
						query: waitForEditionChangeQuery,
						data: {
							tenant: {
								edition: polledEdition,
								__typename: 'ConfluenceEditions',
							},
						},
					});
					resolve(polledEdition);
				} else {
					setTimeout(() => {
						pollAndResolve(resolve, reject);
					}, CHANGE_EDITION_POLL_INTERVAL_SECS * 1000);
				}
			})
			.catch(reject);
	};

	return new Promise<ConfluenceEdition>((resolve, reject) => pollAndResolve(resolve, reject));
};
